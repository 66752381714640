$(document).ready(function() {
    document.getElementById("ReloadPlaceholders").addEventListener("click",
        function(){
            event.preventDefault();
            const xmlHttp = new XMLHttpRequest();
            const my_url = "/home/companies/" + window.location.pathname.split("/")[3] + "/disbursements/placeholders"
            xmlHttp.open( "GET", my_url, true ); // false for synchronous request
            xmlHttp.onload = (e) => {
                let customers = JSON.parse(xmlHttp.responseText);
                let selects = document.getElementsByClassName('placeholders');
                for (var i = 0; i < selects.length; ++i) {
                    var select = selects[i];
                    select.innerHTML =''; // drop down leeren
                    let blankOption = new Option('', '');
                    select.appendChild(blankOption);
                    customers.forEach(function(customer) {
                        let newOption = new Option(customer['name'], customer['id']);
                        select.appendChild(newOption);
                    })
                }
            };
            xmlHttp.onerror = (e) => {
                console.error(xmlHttp.statusText);
            };
            xmlHttp.send(null);
            console.log("reloaded standard customers");
        }
    );
});